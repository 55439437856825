import { toPlainText } from '@portabletext/react'

import { dayjs } from '/machinery/dayjs'
import { sluggify } from '/machinery/makeSlug'

export function date(utcTimestamp) {
  return dayjs(utcTimestamp).utc().format('YYYY-MM-DD')
}

export function dateWithoutDashes(utcTimestamp) {
  return dayjs(utcTimestamp).utc().format('YYYYMMDD')
}

export function wordCount(contentRaw) {
  const content = typeof contentRaw === 'string'
    ? contentRaw
    : toPlainText(contentRaw ?? [])

  return content.match(/\w+/g)?.length ?? 0
}

export function mapJob(job) {
  return {
    id: job.id,
    type: job.employmentType,
    experienceLevel: job.experienceLevel,
    openings: job.openings,
    datepublished: dateWithoutDashes(job.startDate),
    experience: job.experienceLevel,
    hours: job.hours,
    title: job.title,
    locations: job.locations,
    min_salary: job.salaryFrom,
    max_salary: job.salaryTo,
    expertise: sluggify(job.expertise),
    label: sluggify(job.label),
    education: sluggifySortAndJoin(job.education),
    sector: sluggifySortAndJoin(job.sector),
    skills: sluggifySortAndJoin(job.skills?.map(x => x.name)),
  }
}

function sluggifySortAndJoin(x) {
  return (x || []).sort((a, b) => a.localeCompare(b)).map(x => sluggify(x)).join('||')
}
